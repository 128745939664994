import { FreightPostService } from '../../freight-post/application/freight-post.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FreightService } from 'src/app/freight/application/freight.service';
import { MarketService } from 'src/app/market/application/market.service';
import { ECapabilities } from 'src/app/account/domain/account.model';
import { AccountService } from 'src/app/account/application/account.service';
import { MessagesUiService } from 'src/app/layout/services/messages-ui.service';

@Injectable({
  providedIn: 'root'
})
export class RefreshDataService {

  constructor(
    private freightService: FreightService,
    private freightPostService: FreightPostService,
    private marketService: MarketService,
    private accountService: AccountService,
    private messagesUiService: MessagesUiService,
  ) {
  }

  refreshAll() {
    this.freightService.refreshFreightsInprogress();
    this.freightService.refreshFreightsFinished();
    this.freightService.refreshFreightstWithPendingDocumentation();
    this.accountService.hasCapability$(ECapabilities.VIEW_MARKET).subscribe(
      (hasCap) => {
        if (hasCap) {
          this.marketService.refreshFreightsInMarket();
        }
      }
    );
    this.accountService.hasCapability$(ECapabilities.UPLOAD_FREIGHTS).subscribe(
      (hasCap) => {
        if (hasCap) {
          this.freightPostService.refreshFreightsPostedNotFinished();
          this.freightPostService.refreshFreightsPostedFinished();
        }
      }
    );

    // TODO refresh company documents; refresh invoices

    // TODO refresh users/drivers; refresh vehicles

    // TODO review this, only clear messages related with freights?
    this.messagesUiService.clearMessages();
  }

}
